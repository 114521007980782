/* eslint-disable react/style-prop-object */
import React from 'react';
import {useEffect} from 'react';
import { Link } from 'gatsby';
import {
  Container, Col, Row, Nav, NavItem,
} from 'reactstrap';
import ReactMapboxGl, { Marker, Feature } from 'react-mapbox-gl';

import LogoK from '../../images/K_grau.png';
import Headline from '../Headline/Headline';
import Section from '../Section/Section';

import markerIcon from '../../images/marker.png';
// import instaIcon from '../../images/icons/instagram-icon-lukas.png'
import instaIcon from '../../images/icons/instagram-dark-lukas.png'
import styles from './Footer.module.scss';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoieWFuaWtwZWkiLCJhIjoiY2s5aDQzNzJkMDk0bzNobXd6ZWs2Zm40ciJ9.-Pd04t4-ku2bT0miTd8jYw',
  scrollZoom: false,
});

const Footer = ({ home }) => {

  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.type = 'text/javascript';
  //   script.async = true;
  //   script.innerHTML = '!function($_x,_s,id){var js,fjs=$_x.getElementsByTagName(_s)[0];if(!$_x.getElementById(id)){js = $_x.createElement(_s);js.id = id;js.src = "//platform.docplanner.com/js/widget.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","zl-widget-s");';

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, [])

return (
  <>
    <Container style={{ overflow: 'hidden', paddingBottom: '0px' }}>
      <Section>
        <Headline subtitle="Dr. Kathrin Kostka">Kontakt</Headline>
        <Row noGutters>
          <Col xs={12} md={6}>
            <h3 className="underlineHeadline">Anschrift und Kontakt</h3>
            {/*<div>
              <div className={styles.item}>
                <b>Anschrift:</b>
                <div className={styles.itemContent}>
                  Dr.-Ruer-Platz 1
                  <br />
                  44787 Bochum
                </div>
              </div>
              <br />
              <div className={styles.item}>
                <b>Telefon:</b>
                {' '}
                <a href="tel:023497842810">0234 / 978 428 10</a>
              </div>
              <br />
              <div className={styles.item}>
                <b>Fax:</b>
                {' '}
                0234 / 978 428 29
              </div>
              <br />
              <div className={styles.item}>
                <b>E-Mail:</b>
                <div className={styles.itemContent}>
                <a href="mailto:praxis@kostka-bochum.de" className={styles.maila}>praxis@kostka-bochum.de</a>
                </div>
              </div>
              <div className={styles.item}>
                <b>Anschrift:</b>
                <div className={styles.itemContent}>
                  tets
                </div>
              </div>
            </div>*/}
            <div className={styles.gridAnschrift}>
                <b className={styles.grA11}>Anschrift:</b>
                <div className={[styles.itemContent, styles.grA12].join(' ')}>
                  Dr.-Ruer-Platz 1
                  <br />
                  44787 Bochum
                </div>
              
                <b className={styles.grA21} >Telefon:</b>
                <a href="tel:023497842810" className={styles.grA22}>0234 / 978 428 10</a>
                <b className={styles.grA31} >Fax:</b>
                <p className={styles.grA32} >0234 / 978 428 29</p>
                <b className={styles.grA51} >Instagram:</b>
                <div className={[styles.grA52, styles.instaContainer].join(' ')} ><a href="https://www.instagram.com/dr.kkostka/?igsh=YXo5M3Rob3Jwbzc1&utm_source=qr#"><img src={instaIcon} className={styles.instaIcon} height={25} alt="Instagram" /></a><a href="https://www.instagram.com/dr.kkostka/?igsh=YXo5M3Rob3Jwbzc1&utm_source=qr#">dr.kkostka</a></div>
                <b className={styles.grA41} >E-Mail:</b>
                <a href="mailto:praxis@kostka-bochum.de" className={styles.grA42}>praxis@kostka-bochum.de</a>
              </div>
          </Col>
          <Col xs={12} md={6}>
            <div className={"mobilePaddingTop"}>
            <h3 className="underlineHeadline">Sprechstunden</h3>
            <p>
            Termine nach Vereinbarung (telefonisch oder per Mail).
            </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: 40 }}>
            <p style={{textAlign: "center"}}>
              Sie parken direkt unter dem Dr.-Ruer-Platz im Parkhaus P2.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-md-center" >
          <Col xs={12} md={2}>
            <p style={{textAlign: "center"}}>
            Minibar
            </p>
          </Col>
          <Col xs={0} md={1}>
            <p style={{textAlign: "center"}}>
            *
            </p>
          </Col>
          <Col xs={12} md={2}>
            <p style={{textAlign: "center"}}>
            HD- Flachbild TV
            </p>
          </Col>
          <Col xs={0} md={1}>
            <p style={{textAlign: "center"}}>
            *
            </p>
          </Col>
          <Col xs={12} md={2}>
            <p style={{textAlign: "center"}}>
            WLAN Netz
            </p>
          </Col>
        </Row>
      </Section>
    </Container>
    {/*<Section dark>
      <Container>
        <Row style={{ margin: 0 }}>
          <Col>
            Sie können ihren Termin unkompliziert online vereinbaren. Natürlich können Sie uns auch telefonisch oder per E-Mail erreichen, um einen Termin aus zu machen.
            <br />
            <br />
            <br />
            <a
              href="https://onlinetermine.zollsoft.de/patientenTermine.php?uniqueident=5fbe53e2056f0"
              style={{
                margin: '0 auto', display: 'table', color: '#7B7B7A', background: '#F1F0EB', padding: '10px 20px',
              }}
            >
              Termin vereinbaren

            </a>
          </Col>
        </Row>
      </Container>
            </Section>*/}

    <Map
      style="mapbox://styles/yanikpei/ckex60cqd1evq19pdkojjcall"
      containerStyle={{
        height: '500px',
        width: '100vw'
      }}
      center={[7.218510, 51.480090]}
      zoom={[15]}
    >
      <Marker
        coordinates={[7.218510, 51.480090]}
        anchor="bottom"
        onClick={() => {
          window.open(
            'https://www.google.com/maps/place/Dr.-Ruer-Platz+1,+44787+Bochum/@51.4800234,7.2184917,20.11z/data=!4m5!3m4!1s0x47b8e01454d5567b:0xb8f8cbab976f05ef!8m2!3d51.4800443!4d7.2184837',
            '_blank', // <- This is what makes it open in a new window.
          );
        }}
      >
        <img src={markerIcon} height={50} alt="Marker" />
      </Marker>
    </Map>
    {/*
      home && (
      <Section>
        <Container>
          <Row style={{ margin: 0 }}>
            <Col>Wenn Sie mehr über unser Leistungsspektrum erfahren wollen, haben wir einige interessante Aspekte für Sie zusammengestellt. Schauen Sie gerne im Bereiche Leistungen vorbei. Dort sind in den drei Kategorien Schmerz, Ernährung und Ästhetik verständliche Erklärungen zu verschiedenen Verfahren zusammengestellt. Zögern Sie nicht, uns bei Fragen anzusprechen.</Col>
          </Row>
        </Container>
      </Section>
      )
      */}
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.nav}>
          <Nav navbar className={styles.links}>
            <NavItem>
              <Link className={[styles.link].join(' ')} to="/">Start</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link].join(' ')} to="/leistungen/schmerz">Schmerz</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link].join(' ')} to="/leistungen/ernaehrung">Ernährung</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link].join(' ')} to="/leistungen/aesthetik">Ästhetik</Link>
            </NavItem>
            {/* <NavItem>
              <Link className={[styles.link].join(' ')} to="/aktuelles">Aktuelles</Link>
            </NavItem> */}
            <NavItem>
              <Link className={[styles.link].join(' ')} to="/kontakt">Kontakt</Link>
            </NavItem>
          </Nav>
        </div>
        <div className={styles.relative}>
          <img src={LogoK} alt="Logo" />
          <div className={styles.copyright}>
            Praxis für integrative Medizin und Ästhetik - Dr. Kathrin Kostka -
            {' '}
            {new Date().getFullYear()}
            {' '}
            -
            {' '}
            <Link className={styles.link} to="/impressum">Impressum</Link>
            {' '}
            -
            {' '}
            <Link className={styles.link} to="/datenschutz">Datenschutz</Link>
          </div>
        </div>

      </Container>
    </div>
  </>
)
};

export default Footer;
